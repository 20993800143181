import { initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";
import React from "react";

const firebaseConfig = {
  apiKey: "AIzaSyDXqvOnDh2l4kPiuUW0xDzj2yMovwIr0Bs",
  authDomain: "noelgolf-website.firebaseapp.com",
  projectId: "noelgolf-website",
  storageBucket: "noelgolf-website.appspot.com",
  messagingSenderId: "569800899404",
  appId: "1:569800899404:web:9d56c10a2798f0f1b2ade9",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const db = getFirestore(app);

let AuthContext = React.createContext(null);

const AuthProvider = ({ children }) => {
  let [initializing, setInitializing] = React.useState(true);
  let [user, setUser] = React.useState(null);

  let authChanged = React.useCallback((firebaseUser) => {
    if (firebaseUser) setUser(firebaseUser);
    setInitializing(false);

    console.log({ firebaseUser });
  }, []);

  React.useEffect(() => {
    const subscriber = onAuthStateChanged(auth, authChanged);
    return subscriber;
  }, [authChanged]);

  let signin = async (newUser, successCallback, errorCallback) => {
    setInitializing(true);
    try {
      let res = await signInWithEmailAndPassword(
        auth,
        newUser.email,
        newUser.password
      );
      if (res.user) return successCallback();

      return errorCallback("Wrong credentials");
    } catch (error) {
      console.error(error);
      alert(error.message);
      return errorCallback("Something went Wrong.");
    }
  };

  let signout = async (callback) => {
    await signOut(auth);
    setUser(null);
    callback();
  };

  return (
    <AuthContext.Provider value={{ initializing, user, signin, signout, db }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
export { AuthContext };
