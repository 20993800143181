import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AdminLogin from "./components/page/AdminLogin";
import { Home } from "./components/page/Home";
import PlayerStatistics from "./components/page/PlayerStatistics";
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" Component={Home} />
          <Route exact path="/admin" Component={AdminLogin} />
          <Route exact path="/stats" Component={PlayerStatistics} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
