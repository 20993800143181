import "./AdminLogin.css";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { auth } from "../../firebase";
import React, { useState } from "react";

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  var submit = (evt) => {
    evt.preventDefault();

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log(userCredential);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  var signout = (evt) => {
    evt.preventDefault();
    signOut(auth);
  };

  return (
    <div class="login-container">
      <div class="form" onSubmit={submit}>
        <form class="login-form">
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">login</button>
        </form>
      </div>
      <button onClick={signout}>sign out</button>
    </div>
  );
};

export default AdminLogin;
